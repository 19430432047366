<template>
  <div
    class="h-100 participantsList"
  >
    <div class="mb-3 colTable">
      <div class="d-flex flex-column flex-md-row align-items-center">
        <div class="w-100 w-lg-75">
          <a-divider orientation="left">
            <h3>Unsaved List</h3>
          </a-divider>
        </div>
        <div class="w-auto d-flex flex-column flex-md-row ml-3">
          <a-button
            shape="round"
            @click.prevent="showConfirmAddTrue"
            :disabled="!dataTableUnsaved.length"
            type="primary"
            icon="save"
            class="mb-3"
            >SAVE</a-button
          >
        </div>
      </div>
      <a-table
        class="table-scroll my-3"
        :pagination="{ pageSize: 5, hideOnSinglePage: true }"
        :columns="column"
        :data-source="dataTableUnsaved"
      ></a-table>
    </div>
    <div class="my-5 colTable">
      <div class="d-flex flex-column flex-md-row align-items-center">
        <div class="w-100 w-lg-75">
          <a-divider orientation="left">
            <h3>Saved List</h3>
          </a-divider>
        </div>
        <div class="w-auto d-flex flex-column flex-md-row ml-3">
          <a-button
            shape="round"
            type="danger"
            icon="delete"
            class="mb-3"
            :disabled="!removedStudents.length"
            @click.prevent="removeSavedStudents"
            >REMOVE</a-button
          >
        </div>
      </div>
      <a-table
        class="table-scroll my-3"
        :pagination="{ pageSize: 5, hideOnSinglePage: true }"
        :columns="column"
        :row-selection="rowSelection"
        :data-source="dataTableSaved"
      ></a-table>
      <br />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
const column = [
  {
    title: 'Name',
    // width: '100%',
    width: 170,
    dataIndex: 'Name',
    key: 'Name',
    ellipsis: true,
  },
  {
    title: 'Class',
    // width: '100%',
    width: 70,
    dataIndex: 'Class',
    key: 'Class',
  },
  // {
  //   title: 'Status',
  //   // width: '100%',
  //   dataIndex: 'Status',
  //   key: 'Status',
  // },
]
export default {
  name: 'DrawerReport',
  components: {
  },
  props: {
    daftarParticipants: {
      type: Array,
    },
    isRemoved: {
      type: Boolean,
    },
    newParticipants: {
      type: Array,
    },
  },
  data() {
    return {
      column,
      removedStudents: [],
      // removedStudentsKey: [],
    }
  },
  methods: {
    removeSavedStudents() {
      this.$emit('removeSavedStudents', { removedStudentsData: this.removedStudents })
    },
    showConfirmAddTrue() {
      this.$emit('showConfirmAddTrue')
    },
    toCbt(id) {
      this.$router.push({ name: 'Result Cbt Teacher', params: { id } })
    },
    fetchNewSesiData() {
      this.fetchSesi()
    },
    number(index) {
      return ++index
    },
    parseMoment(date) {
      return moment(date).format('DD MMMM YYYY')
    },
    onClose() {
      this.$emit('on-close', true)
    },
    toGrading(idSesi) {
      this.$router.push({ name: 'Grading Assignments Teacher', params: { id: idSesi } })
    },
    fetchSesi() {
      this.$store.dispatch('report/GET_SESI_BY_ID_MURID', { idMurid: this.idMurid, idMapel: this.$route.params.idMapel })
        .then(data => {
          // console.log(data)
          const labels = []
          const series = []
          data.studentsChart.dataChart.forEach((el, index) => {
            labels.push(`#${++index}`)
            series.push(el.tugas_nilai)
          })
          const newData = [...this.dataTable]
          const target = newData.find(item => Number(this.idMurid) === item.key)
          this.dataDrawer = {
            ...target,
            ...data,
          }
          this.chartData.labels = labels
          this.chartData.series[0] = series
          // console.log(this.chartData)
          // this.dataDrawer = data
          // this.visibleDrawer = true
        })
    },
    onSelectChange(key, datas) {
      // this.removedStudentsKey = key
      this.removedStudents = datas
    },
  },
  computed: {
    removedStudentsKey() {
      return this.removedStudents.map(student => { return student.key })
    },
    rowSelection() {
      return {
        selectedRowKeys: this.removedStudentsKey,
        onChange: this.onSelectChange,
        getCheckboxProps: record => ({
          // props: {
          //   disabled: record.isParticipant, // Column configuration not to be checked
          //   // name: record.name,
          // },
        }),
      }
    },
    dataTableSaved() {
      // let key = 0
      const arr1 = this.daftarParticipants.map(participant => {
        // key++
        return {
          Name: participant.kelas_tahun_ajaran?.murid?.nama,
          Class: participant.kelas_tahun_ajaran?.kela.tingkat + '-' + participant.kelas_tahun_ajaran?.kela.simbol,
          Status: 'saved',
          key: participant.kelas_tahun_ajaran?.murid?.id,
          idMMurid: participant.kelas_tahun_ajaran?.murid?.id,
          idPeserta: participant.kelas_tahun_ajaran?.id,
        }
      })
      // const arr2 = this.newParticipants.map(participant => {
      //   key++
      //   return {
      //     Name: participant.Name,
      //     Class: participant.Class,
      //     Status: 'unsaved',
      //     key: key,
      //   }
      // })
      // return arr1.concat(arr2)
      return arr1
    },
    dataTableUnsaved() {
      let key = 0
      const arr2 = this.newParticipants.map(participant => {
        key++
        return {
          Name: participant.Name,
          Class: participant.Class,
          Status: 'unsaved',
          key: key,
        }
      })
      return arr2
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    namaMurid() {
      if (this.daftarMurid) {
        return this.daftarMurid.find(murid => murid?.id === this.idMurid).nama
      }
      return ''
    },
  },
  created() {
  },
  watch: {
    isRemoved(newVal) {
      if (newVal) {
        this.removedStudents = []
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.participantsList {
  .colTable {
    .table-scroll {
      .ant-spin-nested-loading .ant-spin-container .ant-table {
        overflow-x: auto;
        // .ant-table-row-cell-ellipsis {
        //   @media (max-width: 769px) {
        //     width: 200px;
        //   }
        // }
      }
    }
  }
}
</style>
